"use client";

import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { cn } from "./utils";

export type IncraAppSelectUFProps = {
  className?: string;
  defaultValue?: string;
  onChangeUF?: (option: UFOption) => void;
};

export type UFOption = { value: string; label: string; dissareaCount: number };

export function AppSelectRD({
  className,
  defaultValue,
  onChangeUF,
}: IncraAppSelectUFProps) {
  const [optionsUF, setOptionsUF] = useState<UFOption[]>([
    { value: "0", label: "Selecione", dissareaCount: 0 },
  ]);

  useEffect(() => {
    // Fetch data from the API
    fetch("/API/incra/ufmun")
      .then((response) => response.json())
      .then((data) => {
        const ufs = data.processado_car;
        const options = Object.keys(ufs).map((ufKey) => ({
          value: ufKey,
          label: ufKey,
          dissareaCount: ufs[ufKey].dissareas.length,
        }));
        setOptionsUF([
          { value: "0", label: "Selecione", dissareaCount: 0 },
          ...options,
        ]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  function onValueChangeUF(value: string) {
    const option = optionsUF.find((item) => item.value === value);
    if (option && onChangeUF) {
      onChangeUF(option);
    }
  }

  return (
    <Select onValueChange={onValueChangeUF} defaultValue={defaultValue || "0"}>
      <SelectTrigger className={cn("", className)}>
        <SelectValue placeholder="Selecione a UF" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {optionsUF.map((item) => (
            <SelectItem value={item.value} key={item.value}>
              {item.label} ({item.dissareaCount} dissemination area)
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
