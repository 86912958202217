import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import * as THREE from 'three';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1Ijoibmlja2JsYWNrd2VsbCIsImEiOiJjbTJkajBjNjYwcmRtMm5wbTRjMnI3ejV0In0.-beMyDk37G1qY--ZaPXnUg';

const SmogPage: React.FC = () => {
    const mapContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!mapContainer.current) return;

        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-streets-v12',
            center: [-119.477829, 49.882114],
            zoom: 8,
            pitch: 60,
            bearing: 0,
            antialias: true
        });

        const modelOrigin: [number, number] = [-119.477829, 49.882114];
        const modelAltitude = 0;
        const modelRotate = [Math.PI / 2, 0, 0];

        const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
            modelOrigin,
            modelAltitude
        );

        const modelTransform = {
            translateX: modelAsMercatorCoordinate.x,
            translateY: modelAsMercatorCoordinate.y,
            translateZ: modelAsMercatorCoordinate.z ?? 0,
            rotateX: modelRotate[0],
            rotateY: modelRotate[1],
            rotateZ: modelRotate[2],
            scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
        };

            map.on('style.load', () => {
                const threeLayer: mapboxgl.CustomLayerInterface & { camera?: THREE.Camera; scene?: THREE.Scene; renderer?: THREE.WebGLRenderer; fogs?: THREE.Mesh[] } = {
                    id: '3d-fog',
                    type: 'custom',
                    renderingMode: '3d',
                    onAdd: function (map: any, gl: WebGLRenderingContext) {
                        this.camera = new THREE.Camera();
                        this.scene = new THREE.Scene();
            
                        const light = new THREE.DirectionalLight(0xffffff, 1);
                        light.position.set(0, -70, 100).normalize();
                        this.scene.add(light);
            
                        const geometry = new THREE.SphereGeometry(10000, 32, 32); // 10km radius
            
                        this.fogs = [];
                        const locations = getFogLocations();
                        locations.forEach(location => {
                            const color = getColorForHealthIndex(location.healthIndex);
                            const material = new THREE.MeshBasicMaterial({
                                color: color,
                                opacity: 0.1,
                                transparent: true
                            });
                            const fog = new THREE.Mesh(geometry, material);
                            const mercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
                                [location.coordinates[0], location.coordinates[1]],
                                modelAltitude
                            );
                            fog.position.set(mercatorCoordinate.x, mercatorCoordinate.y, mercatorCoordinate.z ?? 0);
                            if (this.scene) {
                                this.scene.add(fog);
                            }
                            if (this.fogs) {
                                this.fogs.push(fog);
                            }
                            console.log(`Added fog sphere at ${location.coordinates[0]}, ${location.coordinates[1]} with color ${color}`);
                        });
            
                        this.renderer = new THREE.WebGLRenderer({
                            canvas: map.getCanvas(),
                            context: gl,
                            antialias: true,
                            alpha: true,
                            powerPreference: "high-performance",
                        });
                        this.renderer.setSize(gl.drawingBufferWidth, gl.drawingBufferHeight, false);
                        this.renderer.autoClear = false;
                    },
                    render: function (gl: WebGLRenderingContext, matrix: number[]) {
                        const m = new THREE.Matrix4().fromArray(matrix);
                        if (this.camera) {
                            this.camera.projectionMatrix = m;
                        }
            
                        const rotationX = new THREE.Matrix4().makeRotationAxis(
                            new THREE.Vector3(1, 0, 0),
                            modelTransform.rotateX
                        );
                        const rotationY = new THREE.Matrix4().makeRotationAxis(
                            new THREE.Vector3(0, 1, 0),
                            modelTransform.rotateY
                        );
                        const rotationZ = new THREE.Matrix4().makeRotationAxis(
                            new THREE.Vector3(0, 0, 0.5),
                            modelTransform.rotateZ
                        );
            
                        const l = new THREE.Matrix4()
                            .makeTranslation(
                                modelTransform.translateX,
                                modelTransform.translateY,
                                modelTransform.translateZ
                            )
                            .scale(
                                new THREE.Vector3(
                                    modelTransform.scale,
                                    -modelTransform.scale,
                                    modelTransform.scale
                                )
                            )
                            .multiply(rotationX)
                            .multiply(rotationY)
                            .multiply(rotationZ);
            
                        if (this.camera) {
                            this.camera.projectionMatrix = m.multiply(l);
                        }
            
                        if (this.renderer) {
                            this.renderer.resetState();
                            if (this.scene && this.camera) {
                                this.renderer.render(this.scene, this.camera);
                            }
                        }
                        map.triggerRepaint();
                    }
                };
            
                map.addLayer(threeLayer);
            
                new mapboxgl.Marker()
                    .setLngLat([-119.477829, 49.882114])
                    .addTo(map);
            
                // Fetch and add air quality data
                fetchAirQualityData(map);
            });

        return () => map.remove();
    }, []);

    const fetchAirQualityData = async (map: mapboxgl.Map) => {
        try {
            const response = await fetch('/api/air');
            const text = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, 'text/html');
            
            // Find the table based on its content
            const tables = Array.from(doc.querySelectorAll('table'));
            const table = tables.find(table => table.textContent?.includes("This table shows a summary of current Air Quality Health Index values and forecast maximums"));
            
            if (!table) {
                console.error('Air quality data table not found');
                return;
            }

            const rows = table.querySelectorAll('tbody tr');

            rows.forEach(row => {
                const cells = row.querySelectorAll('td');
                const location = cells[0].innerText.trim();
                const healthIndex = parseInt(cells[1].innerText.trim(), 10);

                const coordinates = getCoordinatesForLocation(location);
                if (coordinates) {
                    const color = getColorForHealthIndex(healthIndex);
                    new mapboxgl.Marker({ color })
                        .setLngLat(coordinates)
                        .setPopup(new mapboxgl.Popup().setText(`Location: ${location}\nHealth Index: ${healthIndex}`))
                        .addTo(map);
                }
            });
        } catch (error) {
            console.error('Error fetching air quality data:', error);
        }
    };

    const getFogLocations = () => {
        return [
            { coordinates: [-117.6593, 49.3233], healthIndex: 3 }, // Castlegar
            { coordinates: [-122.3051, 49.0504], healthIndex: 3 }, // Central Fraser Valley
            { coordinates: [-119.4956, 49.8880], healthIndex: 3 }, // Central Okanagan
            { coordinates: [-124.9385, 49.6841], healthIndex: 3 }, // Comox Valley
            { coordinates: [-115.7694, 49.5120], healthIndex: 3 }, // Cranbrook
            { coordinates: [-123.7079, 48.7829], healthIndex: 3 }, // Duncan
            { coordinates: [-121.7533, 49.1044], healthIndex: 3 }, // Eastern Fraser Valley
            { coordinates: [-120.8464, 56.2524], healthIndex: 3 }, // Fort St. John
            { coordinates: [-120.3273, 50.6745], healthIndex: 3 }, // Kamloops
            { coordinates: [-128.6558, 54.0524], healthIndex: 3 }, // Kitimat
            { coordinates: [-122.8490, 49.2827], healthIndex: 3 }, // Metro Vancouver - NE
            { coordinates: [-123.1216, 49.2827], healthIndex: 3 }, // Metro Vancouver - NW
            { coordinates: [-123.0236, 49.2057], healthIndex: 3 }, // Metro Vancouver - SE
            { coordinates: [-123.1550, 49.2057], healthIndex: 3 }, // Metro Vancouver - SW
            { coordinates: [-123.9401, 49.1659], healthIndex: 3 }, // Nanaimo
            { coordinates: [-119.2720, 50.2670], healthIndex: 3 }, // North Okanagan
            { coordinates: [-122.7497, 53.9171], healthIndex: 3 }, // Prince George
            { coordinates: [-122.4858, 52.9784], healthIndex: 3 }, // Quesnel
            { coordinates: [-127.1651, 54.7804], healthIndex: 3 }, // Smithers
            { coordinates: [-119.5937, 49.4911], healthIndex: 3 }, // South Okanagan
            { coordinates: [-114.8850, 49.7386], healthIndex: 3 }, // Sparwood
            { coordinates: [-123.1552, 49.7016], healthIndex: 3 }, // Squamish
            { coordinates: [-128.5936, 54.5182], healthIndex: 3 }, // Terrace
            { coordinates: [-123.3656, 48.4284], healthIndex: 3 }, // Victoria/Saanich
            { coordinates: [-123.5317, 48.4473], healthIndex: 3 }, // WestShore
            { coordinates: [-122.9574, 50.1163], healthIndex: 3 }, // Whistler
            { coordinates: [-122.1419, 52.1417], healthIndex: 3 }, // Williams Lake
        ];
    };

    const getCoordinatesForLocation = (location: string): [number, number] | null => {
        const locations: { [key: string]: [number, number] } = {
            'Castlegar': [-117.6593, 49.3233],
            'Central Fraser Valley': [-122.3051, 49.0504],
            'Central Okanagan': [-119.4956, 49.8880],
            'Comox Valley': [-124.9385, 49.6841],
            'Cranbrook': [-115.7694, 49.5120],
            'Duncan': [-123.7079, 48.7829],
            'Eastern Fraser Valley': [-121.7533, 49.1044],
            'Fort St. John': [-120.8464, 56.2524],
            'Kamloops': [-120.3273, 50.6745],
            'Kitimat': [-128.6558, 54.0524],
            'Metro Vancouver - NE': [-122.8490, 49.2827],
            'Metro Vancouver - NW': [-123.1216, 49.2827],
            'Metro Vancouver - SE': [-123.0236, 49.2057],
            'Metro Vancouver - SW': [-123.1550, 49.2057],
            'Nanaimo': [-123.9401, 49.1659],
            'North Okanagan': [-119.2720, 50.2670],
            'Prince George': [-122.7497, 53.9171],
            'Quesnel': [-122.4858, 52.9784],
            'Smithers': [-127.1651, 54.7804],
            'South Okanagan': [-119.5937, 49.4911],
            'Sparwood': [-114.8850, 49.7386],
            'Squamish': [-123.1552, 49.7016],
            'Terrace': [-128.5936, 54.5182],
            'Victoria/Saanich': [-123.3656, 48.4284],
            'WestShore': [-123.5317, 48.4473],
            'Whistler': [-122.9574, 50.1163],
            'Williams Lake': [-122.1419, 52.1417]
        };
        return locations[location] || null;
    };

    const getColorForHealthIndex = (healthIndex: number): string => {
        if (healthIndex <= 3) return 'green';
        if (healthIndex <= 6) return 'yellow';
        if (healthIndex <= 10) return 'orange';
        return 'red';
    };

    return <div ref={mapContainer} style={{ width: '100%', height: '100vh' }} />;
};

export default SmogPage;