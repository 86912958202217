import MapboxMap, { FullscreenControl, Layer, LngLatBoundsLike, MapRef, NavigationControl, SkyLayer, useControl } from 'react-map-gl';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {GeoJsonLayer} from '@deck.gl/layers/typed';
import * as turf from '@turf/turf';
import {MapboxOverlay, MapboxOverlayProps} from '@deck.gl/mapbox/typed';
import Dropdown from '../components/Dropdown';
import '../css/Map.css';
import '../css/ModelHex.css';
import { FeatureCollection } from 'geojson';

function DeckGLOverlay(props: MapboxOverlayProps & {
  interleaved?: boolean;
}) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

export function RoadLinksMap() {


  const mapRef = useRef<MapRef>(null);

  type Location = {
    lng: number
    lat: number
    zoom: number
    bounds: LngLatBoundsLike
  }

  const kelowna: Location = useMemo(() => { 
    return{
      lng: -119.5333,
      lat: 49.9313,
      zoom: 9.08,
      bounds: [[-120.75, 49.5], [-118.25, 50.4]],
    }
  }, [])
  
  const vancouver: Location = useMemo(() => {
    return {
      lng: -122.9307,
      lat: 49.1910,
      zoom: 9.82,
      bounds: [[-124, 48.5], [-121.75, 49.7]],
    }
  }, [])

  const [locationDropdownValue, setLocationDropdownValue] = useState<string | null>('Vancouver')
  const [zoomLevelDropdownValue, setZoomLevelDropdownValue] = useState<string>('8')
  const [currentLocation, setCurrentLocation] = useState<Location>(vancouver)
  const [lng, setLng] = useState(currentLocation.lng);
  const [lat, setLat] = useState(currentLocation.lat);
  const [zoom, setZoom] = useState(currentLocation.zoom);
  const [maxBounds, setMaxBounds] = useState<LngLatBoundsLike | undefined>(undefined);
  const [mapFlying, setMapFlying] = useState<boolean>(false);
  const [linksData, setLinksData] = useState<FeatureCollection | null>(null);

  useEffect(() => {
    fetch('api/geoDatabase/getTopoLinks')
    .then(resp => resp.ok ? resp.json() : Promise.reject(resp))
    .then(json => setLinksData(json))
    .catch(err => console.error('Could not load data', err));
  }, []);

  useEffect(() => {
    console.log(linksData)
  }, [linksData])

  const linkLayer = useMemo(() => {  
    if (linksData == null) return null
    const bufferedLinkData = linksData;
    bufferedLinkData.features = linksData.features.flatMap((feature) => {
      const buffer = turf.buffer(feature, 0.01, { units: 'kilometers' }); // Buffer by 0.01 km (adjust as needed)
      return buffer ?? []
    });
    return new GeoJsonLayer({
      id: 'linkLayer',
      data: bufferedLinkData,
      pickable: true,
      wireframe: false,
      extruded: true,
      getFillColor: [200, 100, 70],
      getElevation: (link) => link.properties?.count_l * 10,
    });
  }, [linksData]);

  const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': zoom
    }
  };

  useMemo(() => {
    if(!mapFlying){
      if(locationDropdownValue == 'Kelowna' && currentLocation != kelowna){
        setCurrentLocation(kelowna)
      }
      if(locationDropdownValue == 'Vancouver' && currentLocation != vancouver){
        setCurrentLocation(vancouver)
      }
    }
  }, [currentLocation, kelowna, locationDropdownValue, mapFlying, vancouver])

  useMemo(() => {
    if(mapRef.current == undefined){return}
    setMaxBounds(undefined)
    setMapFlying(true)
    mapRef.current?.flyTo({
      center: {
        lng: currentLocation.lng, 
        lat: currentLocation.lat
      }, 
      zoom: currentLocation.zoom,
      pitch: 0,
      bearing: 0
    })
  }, [currentLocation])

  function onMapMoveEnd(){
    if(mapFlying){
      setMaxBounds(currentLocation.bounds)
      setMapFlying(false)
      mapRef.current?.easeTo({
        pitch: 50,
        bearing: 0
      })
    }
  }

  const onMapLoad = useCallback(() => {
    mapRef.current?.on('move', () => {
      if(mapRef.current){
        setLng(mapRef.current.getCenter().lng);
        setLat(mapRef.current.getCenter().lat);
        setZoom(mapRef.current.getZoom());
      }
    });
  }, []);

  return (
    <div className='map-container' style={{pointerEvents: mapFlying ? 'none' : 'auto'}}>
      <MapboxMap
        id='map'
        ref={mapRef}
        interactive={true}
        interactiveLayerIds={['hexDataLayer', 'barHexLayer']}
        onLoad={onMapLoad}
        onMoveEnd={onMapMoveEnd}
        mapboxAccessToken="pk.eyJ1Ijoic3R1bWNnIiwiYSI6ImNrcDRtdHE1ZjBiYTkyeHQ4NG5sc3VpM2MifQ.N_cSsEXlTBk7q92Xdpfbug"
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoom,
        }}
        maxBounds={maxBounds}
        mapStyle="mapbox://styles/stumcg/clwl4zrxd00j801rb65kc9ui7"
      >
        <Layer {...skyLayer} />
        <DeckGLOverlay interleaved={true} layers={[linkLayer]} />
        <NavigationControl position='bottom-left' visualizePitch={true}/>
        <FullscreenControl position='bottom-left'/>
        <div className='map-controls' style={{'display': 'flex'}}>
          <div style={{'flex': 'auto'}}>
            <span style={{'fontSize': '20px'}}>
              <div style ={{'float': 'left', textAlign: 'left'}}>
                <div className='dropdown-container'>
                  <Dropdown
                    name={'Location'}
                    options={['Kelowna', 'Vancouver']}
                    value={locationDropdownValue}
                    onChange={(value) => setLocationDropdownValue(value)}
                    anyOption={false}
                  />
                </div>
                <div className='dropdown-container'>
                  <Dropdown
                    name={'Zoom'}
                    options={['7', '8', '9']}
                    value={zoomLevelDropdownValue}
                    onChange={(value) => {if(value != null) setZoomLevelDropdownValue(value)}}
                    anyOption={false}
                  />
                </div>
                <br/>
              </div>
              <br />
            </span>
          </div>
        </div>
      </MapboxMap>
    </div>
  )
}

function RoadLinks(){
  return (
    <>
      <div style={{'height': '100vh', 'width': '100vw'}}>
        <RoadLinksMap/>
      </div>
    </>
  )
}

export default RoadLinks
