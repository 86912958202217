import React, { useEffect, useRef, useState } from 'react';
import maplibregl from 'maplibre-gl';
import { Layers3Icon } from 'lucide-react';
import { FeatureCollection } from 'geojson';

interface BCMapProps {
  setMap: (map: maplibregl.Map) => void;
  addPersistentLayers: (map: maplibregl.Map) => void;
  updateBoundingBox: (map: maplibregl.Map) => void;
  addWmsLayerToMap: (map: maplibregl.Map, layerName: string, layerId: string) => void;
  fetchWFSData: (map: maplibregl.Map, layerName: string, layerId: string, color: string) => void;
}

const BCMap: React.FC<BCMapProps> = ({ setMap, addPersistentLayers, updateBoundingBox, fetchWFSData: fetchWFSDataProp }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const [mapInstance, setMapInstance] = useState<maplibregl.Map | null>(null);
  const [openLayers, setOpenLayers] = useState(false);
  const [isLimiteOpen, setIsLimiteOpen] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const rasterLayers = [
    { id: 'okubc:osm_together', source: 'wms' },
    { id: 'okubc:bc_osm_buildings', source: 'wms' },
    { id: 'okubc:bc_osm_landuse', source: 'wms' },
    { id: 'okubc:bc_osm_natural', source: 'wms' },
    { id: 'okubc:bc_osm_roads', source: 'wms' },
    { id: 'okubc:bc_osm_water', source: 'wms' },
    { id: 'okubc:bc_osm_waterways', source: 'wms' },
    { id: 'okubc:bc_road_atlas', source: 'wms' },
  ];

  const vectorLayers = [
    { id: 'okubc:bc_osm_buildings', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_landuse', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_natural', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_roads', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_water', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_waterways', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_road_atlas', source: 'wfs', color: '#FF5F1F' },
  ];

  const buildingHeights = {
    House: 10,
    Detached: 12,
    Residential: 15,
    Garage: 5,
    Apartments: 20,
    Shed: 4,
    Null: 8,
    Commercial: 25,
    Terrace: 18,
    Industrial: 30,
    Retail: 22,
    'Semidetached House': 14,
    Roof: 6,
    School: 12,
    Greenhouse: 5,
    'Static Caravan': 3,
    Church: 20,
    Cabin: 7,
  };

  const getRandomHeight = (baseHeight: number) => {
    const randomFactor = 0.5 + Math.random() * 2; // Random factor between 1 and 1.5
    return baseHeight * randomFactor;
  };

  const fetchWFSDataInternal = (mapInstance: maplibregl.Map, layerName: string, layerId: string, color: string) => {
    const boundingBox = mapInstance.getBounds().toArray().flat().join(","); // Define boundingBox
    if (!boundingBox) return; // Ensure bounding box is available

    const bboxArray = boundingBox.split(",").map(parseFloat);
    if (bboxArray.length !== 4) {
      console.error("Invalid bounding box:", boundingBox);
      return;
    }

    // Destructure the bounding box into appropriate variables
    const [west, south, east, north] = bboxArray;

    // Construct the CQL filter using BBOX
    const cqlFilter = `BBOX=${south},${west},${north},${east},urn:ogc:def:crs:EPSG:4326`;

    // Updated WFS request
    fetch(`/api/geoserver/wfs?service=WFS&request=GetFeature&typeName=${layerName}&maxFeatures=500&outputFormat=application/json&${cqlFilter}`)
      .then((response) => response.json())
      .then((data: FeatureCollection) => {
        if (data.features.length > 0) {
          data.features.forEach((feature) => {
            if (feature.properties) {
              const buildingType = feature.properties.type as keyof typeof buildingHeights || 'Null';
              const baseHeight = buildingHeights[buildingType] || 10;
              feature.properties.height = getRandomHeight(baseHeight);
              feature.properties.min_height = 0;
            }
          });

          if (mapInstance.getLayer(layerId)) {
            mapInstance.removeLayer(layerId);
            mapInstance.removeSource(`${layerId}-data`);
          }

          // Add the source for GeoJSON data
          mapInstance.addSource(`${layerId}-data`, {
            type: 'geojson',
            data: data, // Use the GeoJSON FeatureCollection directly
          });

          //Add the layer to the map
          mapInstance.addLayer({
            id: layerId,
            type: 'fill-extrusion',
            source: `${layerId}-data`,
            paint: {
              'fill-extrusion-color': color,
              'fill-extrusion-height': ['get', 'height'],
              'fill-extrusion-base': ['get', 'min_height'],
              'fill-extrusion-opacity': 0.6,
            },
            layout: {
              visibility: 'none', // Ensure the layer is visible
            },
          });
        }
      })
      .catch((error) => console.error(`Error fetching ${layerName} data:`, error));
  };

  const fetch3DBuildings = (mapInstance: maplibregl.Map) => {
    // Fetch and add 3D buildings data
    fetchWFSDataInternal2(mapInstance);
  };

  const addWmsLayerToMapInternal = (mapInstance: maplibregl.Map, layerName: string, layerId: string) => {
    if (mapInstance.getLayer(layerId)) {
      mapInstance.removeLayer(layerId);
    }
    if (mapInstance.getSource(layerId)) {
      mapInstance.removeSource(layerId);
    }

    if (!mapInstance.getSource(layerId)) {
      mapInstance.addSource(layerId, {
        type: 'raster',
        tiles: [
          `/api/geoserver/wms?service=WMS&request=GetMap&layers=${layerName}&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&format=image/png&transparent=true`
        ],
        tileSize: 256,
      });

      mapInstance.addLayer({
        id: layerId,
        type: 'raster',
        source: layerId,
        paint: {
          'raster-opacity': 0.8,
        },
        layout: {
          visibility: 'none', // Ensure the layer is not visible
        },
      });
    }
  };

  useEffect(() => {
    if (mapContainer.current && !mapInstance) {
      const map = new maplibregl.Map({
        container: mapContainer.current,
        style: {
          version: 8,
          sources: {
            'osm-tiles': {
              type: 'raster',
              tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
              tileSize: 256,
            },
          },
          layers: [
            {
              id: 'osm-tiles',
              type: 'raster',
              source: 'osm-tiles',
              minzoom: 0,
              maxzoom: 22,
            },
          ],
        },
        center: [-119.42295024465491, 49.879248856855554],
        zoom: 11,
        pitch: 45,
        bearing: -17.6,
        antialias: true,
      });

      const navControl = new maplibregl.NavigationControl();
      map.addControl(navControl, 'top-right');

      map.on('load', () => {
        setMapInstance(map);
        setMap(map);
        addPersistentLayers(map);
        updateBoundingBox(map); // Get bounding box on load

        // Add layers on initial load
        rasterLayers.forEach(layer => {
          addWmsLayerToMapInternal(map, layer.id, `${layer.id}-${layer.source}`);
        });
        vectorLayers.forEach(layer => {
          fetchWFSDataInternal(map, layer.id, `${layer.id}-${layer.source}`, layer.color!);
        });

        // Add 3D buildings layer
        fetch3DBuildings(map);
      });
    }
  }, [mapContainer, mapInstance, setMap, addPersistentLayers, updateBoundingBox]); // Ensure this effect runs only once



  useEffect(() => {
    if (mapInstance) {
      mapInstance.on("style.load", () => {
        addPersistentLayers(mapInstance); // Add layers when the style is fully loaded
      });
    }
  }, [mapInstance]);

  useEffect(() => {
    if (mapInstance) {
      // Update bounding box on move/zoom
      const handleMoveEnd = () => {
        updateBoundingBox(mapInstance);
        rasterLayers.forEach(layer => {
          addWmsLayerToMapInternal(mapInstance, layer.id, `${layer.id}-${layer.source}`);
        });
        vectorLayers.forEach(layer => {
          fetchWFSDataInternal(mapInstance, layer.id, `${layer.id}-${layer.source}`, layer.color!);
        });
        fetch3DBuildings(mapInstance);
      };

      mapInstance.on('moveend', handleMoveEnd);
      mapInstance.on('zoomend', handleMoveEnd);

      return () => {
        mapInstance.off('moveend', handleMoveEnd);
        mapInstance.off('zoomend', handleMoveEnd);
      };
    }
  }, [mapInstance, updateBoundingBox, fetchWFSDataProp, rasterLayers, vectorLayers]); // Ensure this effect runs only once

  const handleOpacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpacity(parseFloat(event.target.value));
  };

  const getBuildingColor = (buildingType: string) => {
    const colors: { [key: string]: string } = {
      House: '#FF5F1F',
      Detached: '#FF7F50',
      Residential: '#FF4500',
      Garage: '#FFD700',
      Apartments: '#FF6347',
      Shed: '#FFA500',
      Null: '#808080',
      Commercial: '#8B0000',
      Terrace: '#FF8C00',
      Industrial: '#B22222',
      Retail: '#DC143C',
      'Semidetached House': '#FF69B4',
      Roof: '#FF1493',
      School: '#FF00FF',
      Greenhouse: '#ADFF2F',
      'Static Caravan': '#7FFF00',
      Church: '#32CD32',
      Cabin: '#00FF00',
    };
    return colors[buildingType] || '#FFFFFF'; // Default to white if type not found
  };

  const fetchWFSDataInternal2 = (mapInstance: maplibregl.Map) => {
    const layerName = 'okubc:bc_osm_buildings';
    const layerId = 'okubc:bc_osm_buildings-extrusion';
    const boundingBox = mapInstance.getBounds().toArray().flat().join(","); // Define boundingBox
    if (!boundingBox) return; // Ensure bounding box is available

    const bboxArray = boundingBox.split(",").map(parseFloat);
    if (bboxArray.length !== 4) {
      console.error("Invalid bounding box:", boundingBox);
      return;
    }

    // Destructure the bounding box into appropriate variables
    const [west, south, east, north] = bboxArray;

    // Construct the CQL filter using BBOX
    const cqlFilter = `BBOX=${south},${west},${north},${east},urn:ogc:def:crs:EPSG:4326`;

    // Updated WFS request
    fetch(`/api/geoserver/wfs?service=WFS&request=GetFeature&typeName=${layerName}&maxFeatures=500&outputFormat=application/json&${cqlFilter}`)
      .then((response) => response.json())
      .then((data: FeatureCollection) => {
        if (data.features.length > 0) {
          data.features.forEach((feature) => {
            if (feature.properties) {
              const buildingType = feature.properties.type as keyof typeof buildingHeights || 'Null';
              const baseHeight = buildingHeights[buildingType] || 10;
              feature.properties.height = getRandomHeight(baseHeight);
              feature.properties.min_height = 0;
              feature.properties.color = getBuildingColor(buildingType);
            }
          });

          if (mapInstance.getLayer(layerId)) {
            mapInstance.removeLayer(layerId);
            mapInstance.removeSource(`${layerId}-data`);
          }

          // Add the source for GeoJSON data
          mapInstance.addSource(`${layerId}-data`, {
            type: 'geojson',
            data: data, // Use the GeoJSON FeatureCollection directly
          });

          // Add the layer to the map
          mapInstance.addLayer({
            id: layerId,
            type: 'fill-extrusion',
            source: `${layerId}-data`,
            paint: {
              'fill-extrusion-color': ['get', 'color'],
              'fill-extrusion-height': ['get', 'height'],
              'fill-extrusion-base': ['get', 'min_height'],
              'fill-extrusion-opacity': 0.6,
            },
            layout: {
              visibility: 'none', // Ensure the layer is not visible
            },
          });
        }
      })
      .catch((error) => console.error(`Error fetching ${layerName} data:`, error));
  };



  function toggleLayerVisibility(layerId: string, checked: boolean): void {
    if (mapInstance) {
      const visibility = checked ? 'visible' : 'none';
      mapInstance.setLayoutProperty(layerId, 'visibility', visibility);
    }
  }

  return (
    <div className="relative" style={{ width: '100%', height: '500px' }}>
      <div ref={mapContainer} className="map-container" style={{ width: '100%', height: '100%', position: 'relative' }}>
      {/* Layer Toggle Button */}
      <div
        className="absolute right-2 bottom-10 bg-white p-2 rounded-lg cursor-pointer shadow-lg"
        style={{ zIndex: 10 }}
        onClick={() => {
        setOpenLayers(!openLayers);
        addPersistentLayers(mapInstance!);
        }}
        aria-label="Toggle layer controls"
      >
        <Layers3Icon className="text-gray-500" />
      </div>

      {/* Layer Controls */}
      {openLayers && (
        <div
        className="absolute right-2 bottom-24 bg-white px-2 py-1 rounded-lg w-[220px] shadow-lg"
        style={{ zIndex: 10 }}
        >
        <div className="flex flex-col space-y-2">
          <p className="text-sm">Click stack icon to update raster layers</p>
          <div className="mb-4">
          <h6
            className="font-bold cursor-pointer"
            onClick={() => setIsLimiteOpen(!isLimiteOpen)}
          >
            Layers {isLimiteOpen ? '▲' : '▼'}
          </h6>
          {isLimiteOpen && (
            <div className="flex flex-col space-y-2">
            <h6 className="font-bold">3D Buildings</h6>
            <label key='3d-buildings'>
              <input
              className="mr-1"
              type="checkbox"
              onChange={(e) => toggleLayerVisibility('okubc:bc_osm_buildings-extrusion', e.target.checked)}
              />
              3D Buildings
            </label>
            <h6 className="font-bold">Raster Layers</h6>
            {rasterLayers.map((layer) => (
              <label key={layer.id}>
              <input
                className="mr-1"
                type="checkbox"
                onChange={(e) => toggleLayerVisibility(`${layer.id}-${layer.source}`, e.target.checked)}
              />
              {layer.id}
              </label>
            ))}
            <h6 className="font-bold">Vector Layers</h6>
            {vectorLayers.map((layer) => (
              <label key={layer.id}>
              <input
                className="mr-1"
                type="checkbox"
                onChange={(e) => toggleLayerVisibility(`${layer.id}-${layer.source}`, e.target.checked)}
              />
              {layer.id}
              </label>
            ))}
            </div>
          )}
          </div>
          <div>
          <label htmlFor="opacity" className="text-sm">
            Raster Layer Opacity:
          </label>
          <input
            id="opacity"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={opacity}
            onChange={handleOpacityChange}
            className="w-full"
          />
          </div>
        </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default BCMap;