import React, { useRef, useEffect } from 'react';
import { Layers3Icon } from 'lucide-react';


interface BCMapMenusProps {
    map: mapboxgl.Map | null;
      toggleLayerVisibility: (layerId: string, source: string) => void;
      isLimiteOpen: boolean;
      setIsLimiteOpen: (open: boolean) => void;
      handleOpacityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
      opacity: number;
    }
  

const BCMapMenus: React.FC<BCMapMenusProps> = ({
  map,
  toggleLayerVisibility,
  isLimiteOpen,
  setIsLimiteOpen,
  handleOpacityChange,
  opacity,
}) => {
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.style.zIndex = '1000';
    }
    if (dropdownRef.current) {
      dropdownRef.current.style.zIndex = '1000';
    }
  }, []);

  const rasterLayers = [
    { id: 'okubc:osm_together', source: 'wms' },
    { id: 'okubc:bc_osm_buildings', source: 'wms' },
    { id: 'okubc:bc_osm_landuse', source: 'wms' },
    { id: 'okubc:bc_osm_natural', source: 'wms' },
    { id: 'okubc:bc_osm_roads', source: 'wms' },
    { id: 'okubc:bc_osm_water', source: 'wms' },
    { id: 'okubc:bc_osm_waterways', source: 'wms' },
    { id: 'okubc:bc_road_atlas', source: 'wms' },
  ];

  const vectorLayers = [
    { id: 'okubc:bc_osm_buildings', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_landuse', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_natural', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_roads', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_water', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_waterways', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_road_atlas', source: 'wfs', color: '#FF5F1F' },
  ];

  useEffect(() => {
    if (map) {
      [...rasterLayers, ...vectorLayers].forEach((layer) => {
        if (map.getLayer(`${layer.id}-${layer.source}`)) {
          map.setLayoutProperty(`${layer.id}-${layer.source}`, 'visibility', 'none');
        }
      });
    }
  }, [map, rasterLayers, vectorLayers]);

  return (
    <>
      {/* Layer Toggle Button */}
      <div
        ref={buttonRef}
        className="absolute right-2 bottom-10 bg-white p-2 rounded-lg cursor-pointer shadow-lg"
        onClick={() => setIsLimiteOpen(!isLimiteOpen)}
        aria-label="Toggle layer controls"
      >
        <Layers3Icon className="text-gray-500" />
      </div>

      {/* Layer Controls */}
      {isLimiteOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-2 bottom-24 bg-white px-2 py-1 rounded-lg w-[220px] shadow-lg"
        >
          <div className="flex flex-col space-y-2">
            <p className="text-sm">Click stack icon to update raster layers</p>
            <div className="mb-4">
              <h6 className="font-bold cursor-pointer">
                Layers {isLimiteOpen ? "▲" : "▼"}
              </h6>
              <div className="flex flex-col space-y-2">
                <h6 className="font-bold">Raster Layers</h6>
                {rasterLayers.map((layer) => (
                  <label key={layer.id}>
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => toggleLayerVisibility(layer.id, layer.source)}
                    />
                    {layer.id}
                  </label>
                ))}
                <h6 className="font-bold">Vector Layers</h6>
                {vectorLayers.map((layer) => (
                  <label key={layer.id}>
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => toggleLayerVisibility(layer.id, layer.source)}
                    />
                    {layer.id}
                  </label>
                ))}
              </div>
            </div>
            <div>
              <label htmlFor="opacity" className="text-sm">
                Raster Layer Opacity:
              </label>
              <input
                id="opacity"
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={opacity}
                onChange={handleOpacityChange}
                className="w-full"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BCMapMenus;