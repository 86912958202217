import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Layers3Icon } from 'lucide-react';

mapboxgl.accessToken =  "pk.eyJ1IjoibG1iaW9uZG8iLCJhIjoiY2xzY2FlcmQ0MG1mMTJqbHBjejVnMjA5cyJ9.Hj4BgunRl3JpyLXMOHE9xA";

interface BCMapProps {
  setMap: (map: mapboxgl.Map) => void;
  addPersistentLayers: (map: mapboxgl.Map) => void;
  updateBoundingBox: (map: mapboxgl.Map) => void;
  addWmsLayerToMap: (map: mapboxgl.Map, layerName: string, layerId: string) => void;
  fetchWFSData: (map: mapboxgl.Map, layerName: string, layerId: string, color: string) => void;
}

const BCMap: React.FC<BCMapProps> = ({ setMap, addPersistentLayers, updateBoundingBox, addWmsLayerToMap, fetchWFSData }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const [openLayers, setOpenLayers] = useState(false);
  const [isLimiteOpen, setIsLimiteOpen] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const rasterLayers = [
    { id: 'okubc:osm_together', source: 'wms' },
    { id: 'okubc:bc_osm_buildings', source: 'wms' },
    { id: 'okubc:bc_osm_landuse', source: 'wms' },
    { id: 'okubc:bc_osm_natural', source: 'wms' },
    { id: 'okubc:bc_osm_roads', source: 'wms' },
    { id: 'okubc:bc_osm_water', source: 'wms' },
    { id: 'okubc:bc_osm_waterways', source: 'wms' },
    { id: 'okubc:bc_road_atlas', source: 'wms' },
  ];

  const vectorLayers = [
    { id: 'okubc:bc_osm_buildings', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_landuse', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_natural', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_roads', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_water', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_osm_waterways', source: 'wfs', color: '#FF5F1F' },
    { id: 'okubc:bc_road_atlas', source: 'wfs', color: '#FF5F1F' },
  ];

  useEffect(() => {
    if (mapContainer.current && !mapInstance) {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-119.42295024465491, 49.879248856855554],
        zoom: 12,
      });

      const navControl = new mapboxgl.NavigationControl();
      map.addControl(navControl, 'top-right');

      map.on('load', () => {
        setMapInstance(map);
        setMap(map);
        addPersistentLayers(map);
        updateBoundingBox(map); // Get bounding box on load

        // Add layers on initial load
        rasterLayers.forEach(layer => {
          addWmsLayerToMap(map, layer.id, `${layer.id}-${layer.source}`);
        });
        vectorLayers.forEach(layer => {
          fetchWFSData(map, layer.id, `${layer.id}-${layer.source}`, layer.color!);
        });
      });
    }
  }, [mapContainer, mapInstance, setMap, addPersistentLayers, updateBoundingBox]); // Ensure this effect runs only once

  useEffect(() => {
    if (mapInstance) {
      mapInstance.on("style.load", () => {
        addPersistentLayers(mapInstance); // Add layers when the style is fully loaded
      });
    }
  }, [mapInstance]);

  useEffect(() => {
    if (mapInstance) {
      // Update bounding box on move/zoom
      const handleMoveEnd = () => {
        updateBoundingBox(mapInstance);
        rasterLayers.forEach(layer => {
          addWmsLayerToMap(mapInstance, layer.id, `${layer.id}-${layer.source}`);
        });
        vectorLayers.forEach(layer => {
          fetchWFSData(mapInstance, layer.id, `${layer.id}-${layer.source}`, layer.color!);
        });
      };

      mapInstance.on('moveend', handleMoveEnd);
      mapInstance.on('zoomend', handleMoveEnd);

      return () => {
        mapInstance.off('moveend', handleMoveEnd);
        mapInstance.off('zoomend', handleMoveEnd);
      };
    }
  }, [mapInstance, updateBoundingBox, addWmsLayerToMap, fetchWFSData, rasterLayers, vectorLayers]); // Ensure this effect runs only once

  useEffect(() => {
    if (mapInstance) {
      class BaseLayerControl {
        map: any;
        container!: HTMLDivElement;
        buttonContainer!: HTMLDivElement;
        isExpanded: boolean = false; 

        onAdd(mapInstance: any) {
          this.map = mapInstance;
          this.container = document.createElement("div");
          this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
          
          // Create toggle button
          const toggleButton = document.createElement("button");
          toggleButton.innerHTML = "Base Map";
          toggleButton.style.width = "70px";
          toggleButton.onclick = () => this.toggleControl(); // Toggle on click
          this.container.appendChild(toggleButton);

          // Create button container for layer buttons
          this.buttonContainer = document.createElement("div");
          this.buttonContainer.style.display = "none"; // Initially hidden
          this.container.appendChild(this.buttonContainer);

          // Create Satellite button
          const satelliteButton = document.createElement("button");
          satelliteButton.innerHTML = "Satellite";
          satelliteButton.style.width = "70px";
          satelliteButton.onclick = () =>
            this.switchBaseLayer("mapbox://styles/mapbox/satellite-streets-v12");
          this.buttonContainer.appendChild(satelliteButton);

          // Create Streets button
          const streetButton = document.createElement("button");
          streetButton.innerHTML = "Roads";
          streetButton.style.width = "70px";
          streetButton.onclick = () =>
            this.switchBaseLayer("mapbox://styles/mapbox/streets-v12");
          this.buttonContainer.appendChild(streetButton);

          // Create Light button
          const lightButton = document.createElement("button");
          lightButton.innerHTML = "Light";
          lightButton.style.width = "70px";
          lightButton.onclick = () =>
            this.switchBaseLayer("mapbox://styles/mapbox/light-v11");
          this.buttonContainer.appendChild(lightButton);

          const darkButton = document.createElement("button");
          darkButton.innerHTML = "Dark";
          darkButton.style.width = "70px";
          darkButton.onclick = () =>
            this.switchBaseLayer("mapbox://styles/mapbox/dark-v11");
          this.buttonContainer.appendChild(darkButton);

          const navegarButton = document.createElement("button");
          navegarButton.innerHTML = "Navigate";
          navegarButton.style.width = "70px";
          navegarButton.onclick = () =>
            this.switchBaseLayer("mapbox://styles/mapbox/navigation-day-v1");
          this.buttonContainer.appendChild(navegarButton);

          const explorarButton = document.createElement("button");
          explorarButton.innerHTML = "Outdoors";
          explorarButton.style.width = "70px";
          explorarButton.onclick = () =>
            this.switchBaseLayer("mapbox://styles/mapbox/outdoors-v12");
          this.buttonContainer.appendChild(explorarButton);

          return this.container;
        }

        onRemove() {
          if (this.container.parentNode) {
            this.container.parentNode.removeChild(this.container);
          }
          this.map = undefined;
        }

        // Function to switch the map's base layer by updating the style
        switchBaseLayer(style: string) {
          this.map.setStyle(style);
          this.map.on('style.load', () => {
            addPersistentLayers(this.map);
          });
        }

        // Function to toggle the visibility of the layer buttons
        toggleControl() {
          this.isExpanded = !this.isExpanded; // Toggle state
          this.buttonContainer.style.display = this.isExpanded ? "block" : "none"; // Show or hide buttons
        }
      }

      // Create and add the custom control to the map
      const baseLayerControl = new BaseLayerControl();
      mapInstance.addControl(baseLayerControl, "top-left");
    }
  }, [mapInstance]);

  const handleOpacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpacity(parseFloat(event.target.value));
  };

  function toggleAllLayersVisibility(checked: boolean): void {
    if (mapInstance) {
      const visibility = checked ? 'visible' : 'none';
      mapInstance.getStyle().layers?.forEach((layer) => {
        if (layer.id !== 'background') {
          mapInstance.setLayoutProperty(layer.id, 'visibility', visibility);
        }
      });
    }
  }

  return (
    <div className="relative" style={{ width: '100%', height: '500px' }}>
      <div ref={mapContainer} className="map-container" style={{ width: '100%', height: '100%', position: 'relative' }}>
        {/* Layer Toggle Button */}
        <div
          className="absolute right-2 bottom-10 bg-white p-2 rounded-lg cursor-pointer shadow-lg"
          style={{ zIndex: 10 }}
          onClick={() => {
            setOpenLayers(!openLayers);
            addPersistentLayers(mapInstance!);
          }}
          aria-label="Toggle layer controls"
        >
          <Layers3Icon className="text-gray-500" />
        </div>

        {/* Layer Controls */}
        {openLayers && (
          <div
            className="absolute right-2 bottom-24 bg-white px-2 py-1 rounded-lg w-[220px] shadow-lg"
            style={{ zIndex: 10 }}
          >
            <div className="flex flex-col space-y-2">
              <p className="text-sm">Click stack icon to update raster layers</p>
              <div className="mb-4">
                <h6
                  className="font-bold cursor-pointer"
                  onClick={() => setIsLimiteOpen(!isLimiteOpen)}
                >
                  Layers {isLimiteOpen ? '▲' : '▼'}
                </h6>
                {isLimiteOpen && (
                  <div className="flex flex-col space-y-2">
                    <h6 className="font-bold">Raster Layers</h6>
                    {rasterLayers.map((layer) => (
                      <label key={layer.id}>
                        <input
                          className="mr-1"
                          type="checkbox"
                          onChange={(e) => toggleAllLayersVisibility(e.target.checked)}
                        />
                        {layer.id}
                      </label>
                    ))}
                    <h6 className="font-bold">Vector Layers</h6>
                    {vectorLayers.map((layer) => (
                      <label key={layer.id}>
                        <input
                          className="mr-1"
                          type="checkbox"
                          onChange={(e) => toggleAllLayersVisibility(e.target.checked)}
                        />
                        {layer.id}
                      </label>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <label htmlFor="opacity" className="text-sm">
                  Raster Layer Opacity:
                </label>
                <input
                  id="opacity"
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={opacity}
                  onChange={handleOpacityChange}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BCMap;