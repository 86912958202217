"use client";

import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { cn } from "./utils";

export type BCAppSelectDissareaProps = {
  className?: string;
  RD?: string;
  onChangeDissarea?: (option: any) => void; // Handles the selected option
};

export function AppSelectDissarea({
  className,
  RD,
  onChangeDissarea, // Destructure the prop
}: BCAppSelectDissareaProps) {
  const [dissareaOptions, setDissareaOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (RD) {
      loadDissareas(RD);
    } else {
      setDissareaOptions([]);
    }
  }, [RD]);

  function loadDissareas(RD: string) {
    setDissareaOptions([]);
    setLoading(true);
    fetch("/API/incra/RDmun")
      .then((response) => response.json())
      .then((data) => {
        const dissareas = data.processado_car[RD]?.dissareas.map(
          (dissarea: any) => ({
            value: dissarea,
            label: dissarea,
          })
        );
        setDissareaOptions(dissareas || []); // Handle case when no dissareas are returned
      })
      .catch((error) => {
        console.error("Error fetching dissareas:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {loading ? (
        <div>Loading...</div> // Display loading indicator
      ) : dissareaOptions.length > 0 ? (
        <Select
          onValueChange={(value) => {
            const selected = dissareaOptions.find((option) => option.value === value);
            onChangeDissarea?.(selected); // Call the prop function with the selected option
          }}
        >
          <SelectTrigger className={cn("", className)}>
            <SelectValue placeholder="Select Dissemination Area" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {dissareaOptions.map((item, index) => (
                <SelectItem value={item.value} key={index}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      ) : (
        <div>No options available</div> // Show message when no options are available
      )}
    </>
  );
}
