            "use client";
            
            import { useState } from "react";
            import maplibregl from 'maplibre-gl';
            import { FeatureCollection } from "geojson";
            import { AppSelectRD } from "./components/BCAppSelectRD";
            import { AppSelectDissarea } from "./components/BCAppSelectdissarea";
            import BcMap from "./components/BCMap"; // Import the map component
            import BCMapMenus from "./components/BCMenu"; // Import the menus component
            
            function OsmPage3d() {
              const [rd, setRD] = useState("0");
              const [isLimiteOpen, setIsLimiteOpen] = useState(false);
              const [opacity, setOpacity] = useState(0.4);
              const [map, setMap] = useState<maplibregl.Map | null>(null);
              const [boundingBox, setBoundingBox] = useState<string>("");
            
              function onChangeRD(item: { value: string }) {
                console.log(item);
                setRD(item.value);
              }
            
              
                const toggleLayerVisibility = (layerId: string, source: string) => {
                if (!map) return;
                const fullLayerId = `${layerId}-${source}`;
                const visibility = map.getLayoutProperty(fullLayerId, "visibility");
                map.setLayoutProperty(fullLayerId, "visibility", visibility === "visible" ? "none" : "visible");
              };
              
                            const handleOpacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const newOpacity = parseFloat(event.target.value);
                setOpacity(newOpacity);
              
                if (map) {
                  const layers = [
                    { id: 'okubc:osm_together', source: 'wms' },
                    { id: 'okubc:bc_osm_buildings', source: 'wms' },
                    { id: 'okubc:bc_osm_landuse', source: 'wms' },
                    { id: 'okubc:bc_osm_natural', source: 'wms' },
                    { id: 'okubc:bc_osm_roads', source: 'wms' },
                    { id: 'okubc:bc_osm_water', source: 'wms' },
                    { id: 'okubc:bc_osm_waterways', source: 'wms' },
                    { id: 'okubc:bc_road_atlas', source: 'wms' },
                    { id: 'okubc:bc_osm_buildings', source: 'wfs' },
                    { id: 'okubc:bc_osm_landuse', source: 'wfs' },
                    { id: 'okubc:bc_osm_natural', source: 'wfs' },
                    { id: 'okubc:bc_osm_roads', source: 'wfs' },
                    { id: 'okubc:bc_osm_water', source: 'wfs' },
                    { id: 'okubc:bc_osm_waterways', source: 'wfs' },
                    { id: 'okubc:bc_road_atlas', source: 'wfs' },
                  ];
              
                  layers.forEach((layer) => {
                    const fullLayerId = `${layer.id}-${layer.source}`;
                    if (map.getLayer(fullLayerId)) {
                      map.setPaintProperty(fullLayerId, "raster-opacity", newOpacity);
                    }
                  });
                }
              };
            
              const updateBoundingBox = (mapInstance: maplibregl.Map) => {
                const bounds = mapInstance.getBounds();
                const bboxString = `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`;
                setBoundingBox(bboxString);
              };
            
              const addWmsLayerToMap = (mapInstance: maplibregl.Map, layerName: string, layerId: string) => {
                if (!boundingBox) return; // Ensure bounding box is available
              
                const bboxArray = boundingBox.split(",").map(parseFloat);
              
                if (bboxArray.length !== 4) {
                  console.error("Invalid bounding box:", boundingBox);
                  return;
                }
              
            
                if (mapInstance.getLayer(layerId)) {
                  mapInstance.removeLayer(layerId);
                }
                if (mapInstance.getSource(layerId)) {
                  mapInstance.removeSource(layerId);
                }
              
                if (!mapInstance.getSource(layerId)) {
                  mapInstance.addSource(layerId, {
                    type: 'raster',
                    tiles: [
                      `/api/geoserver/wms?service=WMS&request=GetMap&layers=${layerName}&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&format=image/png&transparent=true`
                    ],
                    tileSize: 256,
                  });
            
                  mapInstance.addLayer({
                    id: layerId,
                    type: 'raster',
                    source: layerId,
                    paint: {
                      'raster-opacity': 0.8,
                    },
                    layout: {
                      visibility: 'none', // Ensure the layer is initially hidden
                    },
                  });
                }
              };
            
              const fetchWFSData = (mapInstance: maplibregl.Map, layerName: string, layerId: string, color: string) => {
                if (!boundingBox) return; // Ensure bounding box is available
            
                const bboxArray = boundingBox.split(",").map(parseFloat);
                if (bboxArray.length !== 4) {
                  console.error("Invalid bounding box:", boundingBox);
                  return;
                }
            
                // Destructure the bounding box into appropriate variables
                const [west, south, east, north] = bboxArray;
            
                // Construct the CQL filter using BBOX
                const cqlFilter = `BBOX=${south},${west},${north},${east},urn:ogc:def:crs:EPSG:4326`;
            
                // Updated WFS request
                fetch(`/api/geoserver/wfs?service=WFS&request=GetFeature&typeName=${layerName}&maxFeatures=500&outputFormat=application/json&${cqlFilter}`)
                  .then((response) => response.json())
                  .then((data: FeatureCollection) => {
                    if (data.features.length > 0) {
                      if (mapInstance.getLayer(layerId)) {
                        mapInstance.removeLayer(layerId);
                        mapInstance.removeSource(`${layerId}-data`);
                      }
            
                      // Add the source for GeoJSON data
                      mapInstance.addSource(`${layerId}-data`, {
                        type: 'geojson',
                        data: data, // Use the GeoJSON FeatureCollection directly
                      });
            
                      // Add the layer to the map
                      mapInstance.addLayer({
                        id: layerId,
                        type: 'fill',
                        source: `${layerId}-data`,
                        paint: {
                          'fill-color': color,
                          'fill-opacity': 0.4,
                        },
                        layout: {
                          visibility: 'none', // Ensure the layer is initially hidden
                        },
                      });
                    }
                  })
                  .catch((error) => console.error(`Error fetching ${layerName} data:`, error));
              };
            
              const addPersistentLayers = (mapInstance: maplibregl.Map) => {
                const rasterLayers = [
                  { id: 'okubc:osm_together', source: 'wms' },
                  { id: 'okubc:bc_osm_buildings', source: 'wms' },
                  { id: 'okubc:bc_osm_landuse', source: 'wms' },
                  { id: 'okubc:bc_osm_natural', source: 'wms' },
                  { id: 'okubc:bc_osm_roads', source: 'wms' },
                  { id: 'okubc:bc_osm_water', source: 'wms' },
                  { id: 'okubc:bc_osm_waterways', source: 'wms' },
                  { id: 'okubc:bc_road_atlas', source: 'wms' },
                ];
              
                const vectorLayers = [
                  { id: 'okubc:bc_osm_buildings', source: 'wfs', color: '#FF5F1F' },
                  { id: 'okubc:bc_osm_landuse', source: 'wfs', color: '#FF5F1F' },
                  { id: 'okubc:bc_osm_natural', source: 'wfs', color: '#FF5F1F' },
                  { id: 'okubc:bc_osm_roads', source: 'wfs', color: '#FF5F1F' },
                  { id: 'okubc:bc_osm_water', source: 'wfs', color: '#FF5F1F' },
                  { id: 'okubc:bc_osm_waterways', source: 'wfs', color: '#FF5F1F' },
                  { id: 'okubc:bc_road_atlas', source: 'wfs', color: '#FF5F1F' },
                ];
              
                rasterLayers.forEach(layer => {
                  addWmsLayerToMap(mapInstance, layer.id, `${layer.id}-${layer.source}`);
                });
              
                vectorLayers.forEach(layer => {
                  fetchWFSData(mapInstance, layer.id, `${layer.id}-${layer.source}`, layer.color!);
                });
              };

              return (
                <>
                  <title>OSM Layers</title>
            
                  <div className="container mx-auto flex">
                    <div className="flex flex-col flex-1 space-y-4">
                      <div className="bg-slate-50 rounded-2xl p-4 border-2 border-verde2-cartorios shadow-lg">
                        <div className="flex space-x-10">
                          <span className="text-verde2-cartorios text-xl leading-3">
                            Open Street Maps Layers BC
                          </span>
                          <div className="border-b-2 border-verde2-cartorios flex-1"></div>
                        </div>

                        <div className="flex items-center mt-[24px] space-x-2">
                          <span>Regional District:</span>
                          <AppSelectRD className="w-[300px]" onChangeUF={onChangeRD} />
                        </div>

                        <div className="flex items-center mt-[24px] space-x-2">
                          <span>Dissemination Area:</span>
                          {rd !== "0" && (
                            <AppSelectDissarea
                              className="w-[300px]"
                              RD={rd}
                            />
                          )}
                        </div>

                        <BcMap
                          setMap={(map: maplibregl.Map) => setMap(map)}
                          addPersistentLayers={(map: maplibregl.Map) => addPersistentLayers(map)}
                          updateBoundingBox={(map: maplibregl.Map) => updateBoundingBox(map)}
                          addWmsLayerToMap={(map: maplibregl.Map, layerName: string, layerId: string) => addWmsLayerToMap(map, layerName, layerId)}
                          fetchWFSData={(map: maplibregl.Map, layerName: string, layerId: string, color: string) => fetchWFSData(map, layerName, layerId, color)}
                        />
                      </div>
                      <div>
                          <BCMapMenus
          
                          toggleLayerVisibility={toggleLayerVisibility}
                          isLimiteOpen={isLimiteOpen}
                          setIsLimiteOpen={setIsLimiteOpen}
                          handleOpacityChange={handleOpacityChange}
                          opacity={opacity} map={null}                          />
                        </div>
                        
                      
                    </div>
                  </div>
                </>
              );
            }

            
            export default OsmPage3d;