"use client";

import maplibregl from "maplibre-gl";
import { useEffect, useRef, useState } from "react";
import proj4 from 'proj4';



const lonLatToMercator = proj4('EPSG:4326', 'EPSG:4326');



interface CustomLayerWithGL extends maplibregl.CustomLayerInterface {
    gl?: WebGLRenderingContext;
    program?: WebGLProgram;
    map?: maplibregl.Map;
    aPosition?: number;
    uMatrix?: WebGLUniformLocation;
    uAltitude?: WebGLUniformLocation;
    cloudPlane?: Float32Array;
    buffer?: WebGLBuffer;
}

//  const createCloudLayer = (map: maplibregl.Map): CustomLayerWithGL => ({
//     id: "cloud-layer",
//     type: "custom",
//     renderingMode: "3d",
//     map, // Ensure map reference

//     onAdd: function (map: maplibregl.Map, gl: WebGLRenderingContext) {
//         this.gl = gl;
//         this.program = gl.createProgram();
//         this.map = map;

//         const vertexShaderSource = `
//             attribute vec3 aPosition;
//             uniform mat4 uMatrix;
//             uniform float uAltitude;
//             varying vec2 vUV;

//             void main() {
//                 vUV = aPosition.xy * 0.5 + 0.5; // Assuming aPosition is in clip-space
//                 vec4 worldPosition = uMatrix * vec4(aPosition, 1.0);
//                 worldPosition.y += uAltitude;
//                 gl_Position = worldPosition;  // Corrected transformation
//             }
//         `;

//         const fragmentShaderSource = `
//             precision mediump float;
//             varying vec2 vUV;

//             float random(vec2 p) {
//                 return fract(sin(dot(p, vec2(12.9898, 78.233))) * 43758.5453);
//             }

//             void main() {
//                 float noise = random(vUV * 20.0);
//                 float alpha = smoothstep(0.3, 0.7, noise);
//                 gl_FragColor = vec4(vec3(1.0), alpha); // Cleaner way to set color
//             }
//         `;

//         const vertexShader = gl.createShader(gl.VERTEX_SHADER)!;
//         gl.shaderSource(vertexShader, vertexShaderSource);
//         gl.compileShader(vertexShader);

//         const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)!;
//         gl.shaderSource(fragmentShader, fragmentShaderSource);
//         gl.compileShader(fragmentShader);

//         gl.attachShader(this.program, vertexShader);
//         gl.attachShader(this.program, fragmentShader);
//         gl.linkProgram(this.program);

        

//         this.aPosition = gl.getAttribLocation(this.program, "aPosition");
//         this.uMatrix = gl.getUniformLocation(this.program, "uMatrix") || undefined;
//         this.uAltitude = gl.getUniformLocation(this.program, "uAltitude") || undefined;

//         // Convert to latitude/longitude coordinates in degrees
//         // Example: The cloud plane will now be a small square around some central lat/lon (e.g., -5° latitude, -60° longitude)
//         const lat = 50; // Latitude in degrees
//         const lon = -119; // Longitude in degrees
//         const size = 20; // Size of the cloud plane in degrees (about 11.1 km per degree)

//         const bottomLeft = lonLatToMercator.forward([lon, lat]); // [x, y] in meters
//         const topRight = lonLatToMercator.forward([lon+size, lat+size]);  // [x, y] in meters



//         this.cloudPlane = new Float32Array([
//             bottomLeft[0], bottomLeft[1], 50,
//             topRight[0], bottomLeft[1], 50,
//             bottomLeft[0], topRight[1], 50,
//             topRight[0], topRight[1], 50,

//             bottomLeft[0], bottomLeft[1], 100,
//             topRight[0], bottomLeft[1], 100,
//             bottomLeft[0], topRight[1], 100,
//             topRight[0], topRight[1], 100
//         ]);

//         // Now create the buffer
//         this.buffer = gl.createBuffer();
//         gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
//         gl.bufferData(gl.ARRAY_BUFFER, this.cloudPlane, gl.STATIC_DRAW);
//     },

//     render: function (gl: WebGLRenderingContext, matrix: any) {
//         if (!this.map) {
//             console.warn("⚠️ Map reference missing in render!");
//             return;
//         }

//         // Check if cloud plane is initialized
//         if (!this.cloudPlane) {
//             console.warn("⚠️ Cloud plane not initialized!");
//             return;
//         }

//         if (this.program) {
//             gl.useProgram(this.program);
//         } else {
//             console.warn("⚠️ WebGL program is not initialized!");
//             return;
//         }
//         gl.enable(gl.BLEND);
//         gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

//         const zoom = this.map.getZoom();
//         const altitude = 1 / zoom * 10000; // Keep it within a reasonable height

//         console.log("🔍 Cloud Layer Render Log:");
//         console.log(`🌍 Map Zoom Level: ${zoom}`);
//         console.log(`☁️ Computed Altitude: ${altitude} meters`);

//         console.log(`🗺️ Cloud Coordinates:`, this.cloudPlane);
//         console.log("🔹 Cloud Plane First Point:", this.cloudPlane[0], this.cloudPlane[1], this.cloudPlane[2]);
        
//         console.log(gl.getProgramInfoLog(this.program));

//         if (this.uMatrix) {
//             gl.uniformMatrix4fv(this.uMatrix, false, matrix);
//         } else {
//             console.warn("⚠️ uMatrix uniform location is not initialized!");
//         }
//         if (this.uAltitude) {
//             gl.uniform1f(this.uAltitude, altitude);
//         } else {
//             console.warn("⚠️ uAltitude uniform location is not initialized!");
//         }

//         if (this.buffer) {
//             gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
//         } else {
//             console.warn("⚠️ WebGL buffer is not initialized!");
//             return;
//         }
//         if (this.aPosition !== undefined) {
//             gl.enableVertexAttribArray(this.aPosition);
//             gl.vertexAttribPointer(this.aPosition, 3, gl.FLOAT, false, 0, 0);
//         } else {
//             console.warn("⚠️ aPosition attribute location is not initialized!");
//             return;
//         }

//         gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

//         this.map.triggerRepaint();
//     }
// });


const createCloudLayer = (map: maplibregl.Map): CustomLayerWithGL => ({
    id: "cloud-layer",
    type: "custom" as const,
    renderingMode: "3d",

    onAdd: function (map: maplibregl.Map, gl: WebGLRenderingContext) {
        this.gl = gl;
        this.program = gl.createProgram();
        this.map = map;

        gl.disable(gl.DEPTH_TEST);
        gl.disable(gl.STENCIL_TEST);
        

        const vertexShaderSource = `
            attribute vec3 aPosition;
            uniform mat4 uMatrix;
            uniform float uAltitude;
            varying vec2 vUV;
            void main() {
                // Normalize UV coordinates
                vUV = (aPosition.xy + 50.0) / 100.0;

                // Apply transformation
                vec4 worldPosition = uMatrix * vec4(aPosition, 1.0);

                // Adjust for altitude (AFTER transformation)
                worldPosition.y += uAltitude;

                // Output final position
                gl_Position = worldPosition;
            }
        `;

        const fragmentShaderSource = `
                    precision mediump float;
                    varying vec2 vUV;
                    float random(vec2 p) {
                        return fract(sin(dot(p, vec2(12.9898, 78.233))) * 43758.5453);
                    }
                    void main() {
                        float noise = random(vUV * 20.0);
                        float alpha = smoothstep(0.3, 0.7, noise);
                        gl_FragColor = vec4(1.0, 1.0, 1.0, alpha * 0.5);
                    }
                `;

        const vertexShader = gl.createShader(gl.VERTEX_SHADER)!;
        gl.shaderSource(vertexShader, vertexShaderSource);
        gl.compileShader(vertexShader);

        const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)!;
        gl.shaderSource(fragmentShader, fragmentShaderSource);
        gl.compileShader(fragmentShader);

        gl.attachShader(this.program, vertexShader);
        gl.attachShader(this.program, fragmentShader);
        gl.linkProgram(this.program);

        this.aPosition = gl.getAttribLocation(this.program, "aPosition");
        this.uMatrix = gl.getUniformLocation(this.program, "uMatrix") || undefined;
        this.uAltitude = gl.getUniformLocation(this.program, "uAltitude") || undefined;

        // Ensure the cloud plane is initialized here
        this.cloudPlane = new Float32Array([
            -1, -1, 0,   0, -1, 0,   -1, 0, 0,  // Triangle 1
            -1, -1, 5,    0, -1, 5,    -1, 0, 5   // Triangle 2
        ]);

        // Now create the buffer
        this.buffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
        gl.bufferData(gl.ARRAY_BUFFER, this.cloudPlane, gl.STATIC_DRAW);
    },

    render: function (gl: WebGLRenderingContext, matrix: any) {
        if (!this.map) {
            console.warn("⚠️ Map reference missing in render!");
            return;
        }

        // Check if cloud plane is initialized
        if (!this.cloudPlane) {
            console.warn("⚠️ Cloud plane not initialized!");
            return;
        }

        if (this.program) {
            gl.useProgram(this.program);
        } else {
            console.warn("⚠️ WebGL program is not initialized!");
        }
        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        const zoom = this.map.getZoom();
        const altitude = zoom * 100; // Keep it within a reasonable height

        console.log("🔍 Cloud Layer Render Log:");
        console.log(`🌍 Map Zoom Level: ${zoom}`);
        console.log(`☁️ Computed Altitude: ${altitude} meters`);

        console.log(`🗺️ Cloud Coordinates:`, this.cloudPlane);
        console.log("🔹 Cloud Plane First Point:", this.cloudPlane[0], this.cloudPlane[1], this.cloudPlane[2]);

        if (this.uMatrix) {
            gl.uniformMatrix4fv(this.uMatrix, false, matrix);
        }
        if (this.uAltitude) {
            gl.uniform1f(this.uAltitude, altitude);
        }

        if (this.buffer) {
            gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
        }
        if (this.aPosition !== undefined) {
            gl.enableVertexAttribArray(this.aPosition);
            gl.vertexAttribPointer(this.aPosition, 3, gl.FLOAT, false, 0, 0);
        }

        gl.drawArrays(gl.TRIANGLES, 0, 6);

        this.map.triggerRepaint();
    }
});




/* const useCloudLayer = (map: maplibregl.Map | null, mapRef: React.MutableRefObject<maplibregl.Map | null>) => {
    useEffect(() => {
        if (!map) {
            console.warn("Map is not initialized.");
            return;
        }

        // Ensure mapRef is set
        if (!mapRef.current) {
            mapRef.current = map;
        }

        const cloudLayer: any = {
            id: "cloud-layer",
            type: "custom",
            renderingMode: "3d",
            onAdd: function (gl: WebGLRenderingContext) {
                this.gl = gl;
                this.program = gl.createProgram();

                gl.disable(gl.DEPTH_TEST);
                gl.disable(gl.STENCIL_TEST);
                

                const vertexShaderSource = `
                    attribute vec3 aPosition;
                    uniform mat4 uMatrix;
                    uniform float uAltitude;
                    varying vec2 vUV;
                    void main() {
                        // Normalize UV coordinates
                        vUV = (aPosition.xy + 50.0) / 100.0;

                        // Apply transformation
                        vec4 worldPosition = uMatrix * vec4(aPosition, 1.0);

                        // Adjust for altitude (AFTER transformation)
                        worldPosition.y += uAltitude;

                        // Output final position
                        gl_Position = worldPosition;
                    }
                `;

                const fragmentShaderSource = `
                    precision mediump float;
                    varying vec2 vUV;
                    float random(vec2 p) {
                        return fract(sin(dot(p, vec2(12.9898, 78.233))) * 43758.5453);
                    }
                    void main() {
                        float noise = random(vUV * 20.0);
                        float alpha = smoothstep(0.3, 0.7, noise);
                        gl_FragColor = vec4(1.0, 1.0, 1.0, alpha * 0.5);
                    }
                `;

                const vertexShader = gl.createShader(gl.VERTEX_SHADER)!;
                gl.shaderSource(vertexShader, vertexShaderSource);
                gl.compileShader(vertexShader);

                const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)!;
                gl.shaderSource(fragmentShader, fragmentShaderSource);
                gl.compileShader(fragmentShader);

                gl.attachShader(this.program, vertexShader);
                gl.attachShader(this.program, fragmentShader);
                gl.linkProgram(this.program);

                this.aPosition = gl.getAttribLocation(this.program, "aPosition");
                this.uMatrix = gl.getUniformLocation(this.program, "uMatrix");
                this.uAltitude = gl.getUniformLocation(this.program, "uAltitude");

                // Cloud plane, now larger for visibility
                this.cloudPlane = new Float32Array([
                    -10, -10, 0,   10, -10, 0,   -10, 10, 0,  // Triangle 1
                    -10, 10, 5,    10, -10, 5,    10, 10, 5   // Triangle 2
                ]);

                this.buffer = gl.createBuffer();
                gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
                gl.bufferData(gl.ARRAY_BUFFER, this.cloudPlane, gl.STATIC_DRAW);
            },
            render: function (gl: WebGLRenderingContext, matrix: any) {
                gl.useProgram(this.program);
                gl.enable(gl.BLEND);
                gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

                const zoom = map.getZoom();
                // const altitude = Math.pow(2, zoom) * 50; // Adjust altitude dynamically
                const altitude = zoom * 100; // Example: At zoom 12 → 12 * 100 = 1200 meters


                console.log("🔍 Cloud Layer Render Log:");
                console.log(`🌍 Map Zoom Level: ${zoom}`);
                console.log(`☁️ Computed Altitude: ${altitude} meters`);
                if (!this.cloudPlane) {
                    console.warn("⚠️ Cloud plane not initialized!");
                } else {
                    console.log(`🗺️ Cloud Coordinates:`, this.cloudPlane);
                }

                gl.uniformMatrix4fv(this.uMatrix, false, matrix);
                gl.uniform1f(this.uAltitude, altitude);

                gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
                gl.enableVertexAttribArray(this.aPosition);
                gl.vertexAttribPointer(this.aPosition, 3, gl.FLOAT, false, 0, 0);

                gl.drawArrays(gl.TRIANGLES, 0, 6);

                map.triggerRepaint();
            }
        };

        map.on('load', () => {
            map.addLayer(cloudLayer);
        });

        return () => {
            if (map.getLayer("cloud-layer")) {
                map.removeLayer("cloud-layer");
            }
        };
    }, [map]);
 } */


       /*  const useFogLayer = (map: maplibregl.Map | null) => {
            useEffect(() => {
                if (!map) return;
        
                const fogLayer: any = {
                    id: "fog-layer",
                    type: "custom",
                    renderingMode: "3d",
                    onAdd: function (gl: WebGLRenderingContext) {
                        this.gl = gl;
                        this.program = gl.createProgram();
        
                        // Vertex Shader: Draws a full-screen quad
                        const vertexShaderSource = `
                            attribute vec2 aPosition;
                            varying vec2 vUV;
                            void main() {
                                vUV = (aPosition + 1.0) * 0.5;
                                gl_Position = vec4(aPosition, 0.0, 1.0);
                            }
                        `;
        
                        // Fragment Shader: Creates a white fog with transparency
                        const fragmentShaderSource = `
                            precision mediump float;
                            varying vec2 vUV;
                            void main() {
                                float fogIntensity = smoothstep(0.2, 1.0, vUV.y);
                                gl_FragColor = vec4(1.0, 1.0, 1.0, fogIntensity * 0.3); // Soft fog effect
                            }
                        `;
        
                        const vertexShader = gl.createShader(gl.VERTEX_SHADER)!;
                        gl.shaderSource(vertexShader, vertexShaderSource);
                        gl.compileShader(vertexShader);
        
                        const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)!;
                        gl.shaderSource(fragmentShader, fragmentShaderSource);
                        gl.compileShader(fragmentShader);
        
                        gl.attachShader(this.program, vertexShader);
                        gl.attachShader(this.program, fragmentShader);
                        gl.linkProgram(this.program);
        
                        this.aPosition = gl.getAttribLocation(this.program, "aPosition");
        
                        this.fogPlane = new Float32Array([
                            -1, -1,
                             1, -1,
                            -1,  1,
                             1,  1
                        ]);
        
                        this.buffer = gl.createBuffer();
                        gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
                        gl.bufferData(gl.ARRAY_BUFFER, this.fogPlane, gl.STATIC_DRAW);
                    },
                    render: function (gl: WebGLRenderingContext) {
                        gl.useProgram(this.program);
                        gl.enable(gl.BLEND);
                        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA); // Enable transparency
        
                        gl.bindBuffer(gl.ARRAY_BUFFER, this.buffer);
                        gl.enableVertexAttribArray(this.aPosition);
                        gl.vertexAttribPointer(this.aPosition, 2, gl.FLOAT, false, 0, 0);
        
                        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
        
                        map.triggerRepaint();
                    }
                };
        
                map.on('load', () => {
                    map.addLayer(fogLayer);
                });
        
                return () => {
                    if (map.getLayer("fog-layer")) {
                        map.removeLayer("fog-layer");
                    }
                };
            }, [map]);
        }; */
        




    const Fog3DPage = () => {
        const mapContainer = useRef<HTMLDivElement | null>(null);
        const mapRef = useRef<maplibregl.Map | null>(null);
        const [mapInstance, setMapInstance] = useState<maplibregl.Map | null>(null);

        useEffect(() => {
            if (mapRef.current) return;

            const map = new maplibregl.Map({
                container: mapContainer.current!,
                style: "https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json",
                center: [-119.496, 49.887], // Kelowna, Canada
                zoom: 12,
                pitch: 45,
                bearing: -17.6,
            });

            mapRef.current = map;
            setMapInstance(map);

            return () => map.remove();
        }, []);

        // Hook will run when the mapInstance is set
        if (mapInstance) {
            const cloudLayer = createCloudLayer(mapInstance) as CustomLayerWithGL;
            mapInstance.on('load', () => {
                mapInstance.addLayer(cloudLayer);
            });
        }

        return <div ref={mapContainer} style={{ width: "100%", height: "100vh" }} />;
    };
        

export default Fog3DPage;
        